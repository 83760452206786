import {xmlDownload} from "../../import/utils/HappyneuronFileDownloader";
import normalizeImporterClasse from "./NormalizeImporterClasse";

const getLocation = async () => {
    return { filePath: '', valid: false };
};

async function parameterizedParseFile(userId, ticket, programId, encoding, classePropertyName) {
    const xmlContent = await xmlDownload({userId, ticket, programId});

    const oDOM = (new window.DOMParser()).parseFromString(xmlContent, 'application/xml');
    const fiches = oDOM.getElementsByTagName('fiches')[0];

    return [...fiches.getElementsByTagName('profil')].flatMap(profilElement =>
        [...profilElement.getElementsByTagName('passation')].map(passation => {
            return ({
                firstname: profilElement.getAttribute('prenom'),
                lastname: profilElement.getAttribute('nom'),
                birthDate: profilElement.getAttribute('datenaissance'),
                classe: profilElement.getAttribute(classePropertyName),
                passationNumber: passation.getAttribute('numero'),
                passationDate: passation.getAttribute('datepassage'),
                cotationCode: normalizeImporterClasse(profilElement.getAttribute('classe')),
                hnWorkoutId: passation.getAttribute('workoutId'),
                data: passation
            });
        })
    );
}

const parseFile = async ({userId, ticket, programId, encoding}) => {
    return await parameterizedParseFile(userId, ticket, programId, encoding, 'classe');
};

export { getLocation, parseFile, parameterizedParseFile };
