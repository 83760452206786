import _ from "lodash";
import buildValues from "./BuildValues";
import calculateValues from "./CalculateValues";
import shouldIgnoreOnClassConstraint from "./ShouldIgnoreOnClassConstraint";
import canCompute from "./CanCompute";
import {inputIncludesQuali} from "./calculateValue/QualiValue";

const updateCurrentTestResultsFromCalculOption = (currentTest, currentTestResults) => {
  const updatedCurrentTestResults = JSON.parse(JSON.stringify(currentTestResults));
  const { examinations, calculationData, columns, disableCentileColumn, currentTestHasCalculOptions } = buildValues(
    currentTest,
    currentTestResults.$$calculOption
  );
  examinations.forEach(({ id, input }) => {
    const { returnValue } =
      updatedCurrentTestResults[id] && updatedCurrentTestResults[id].score
        ? calculateValues(calculationData[id], updatedCurrentTestResults[id].score, input)
        : { returnValue: updatedCurrentTestResults[id] };
    if (!currentTestResults[id]) {
      updatedCurrentTestResults[id] = {};
    }
    const ignore = shouldIgnoreOnClassConstraint(currentTest, currentTestResults);
    updatedCurrentTestResults[id] = _.merge(
      {
        $$HIDDENFROMVIEW: inputIncludesQuali(input) ? false : ((calculationData && !canCompute(calculationData, id)) || ignore),
        $$DISABLETYPING: (calculationData && currentTest.disableTyping) || false,
        $$displayRawScore: true,
        ignore,
        comment: (currentTestResults[id] || {}).comment || ''
      },
      returnValue
    );
  });
  return {
    examinations,
    calculationData,
    columns,
    disableCentileColumn,
    currentTestHasCalculOptions,
    updatedCurrentTestResults
  };
};

export default updateCurrentTestResultsFromCalculOption;
