import {fetchJson, fetchXml} from '../../../common/OrthoscribeWebSite';
import buildInfo from '../../../../buildInfo.json';

const AppName = "Happyscribe";

 const getFilePath = (userId, programId, url) => {
  const fullFilePath = `${(buildInfo.happyneuronUrl)}/users/${userId}/results/programs/${programId}`;
  console.log({fullFilePath})
  return fullFilePath;
};

export default getFilePath;

export const hnParamsDownload = async ({userId, ticket}) => {

  const init = {
    headers: new Headers({
      ticket,
      AppName,
      AppVersion: buildInfo.version
    })
  };

  const hnJsonContent = await fetchJson(`${(buildInfo.happyneuronUrl)}/users/${userId}/happyscribe`, init);
  console.log({hnJsonContent})
  return hnJsonContent;
}

export const jsonDownload = async ({userId, ticket, programId}) => {

  const init = {
    headers: new Headers({
      ticket,
      AppName,
      AppVersion: buildInfo.version
    })
  };
  const hnJsonContent = await fetchJson(`${(buildInfo.happyneuronUrl)}/users/${userId}/results/programs/${programId}`, init);
  console.log({hnJsonContent})
  return hnJsonContent;
}

export const xmlDownload = async ({userId, ticket, programId}) => {

  const init = {
    headers: new Headers({
      ticket,
      AppName,
      AppVersion: buildInfo.version
    })
  };
  const hnXmlContent = await fetchXml(`${(buildInfo.happyneuronUrl)}/users/${userId}/results/programs/${programId}`, init);
  console.log({hnXmlContent})
  return hnXmlContent;
}
