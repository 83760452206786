import moment from 'moment';

import log from '../../Logger';
import appVersionSpecific from '../../../appVersionSpecific.ortho';
import ReportGeneratorUtil from '../../patient/report/ReportGeneratorUtil';
import User from './User';
import {hexEncode} from '../hex';
import MyStore from '../../../actions/MyStore';

export default class UserLogin {
  static async login(payload, email) {
    log.info('Loggin in ');

    const { user, token, aesKey, license, jwt } = payload;

    MyStore.set(appVersionSpecific.electronStoreUserKey, hexEncode(email));
    MyStore.set(appVersionSpecific.electronStoreAesKey, aesKey);
    MyStore.set(appVersionSpecific.electronStoreLastLoginDate, moment());
    MyStore.set('justLoggedIn', true);

    await User.save({ keyValue: 'email', value: email });
    await User.save({ keyValue: 'authToken', value: token });
    await User.save({ keyValue: 'aesKey', value: aesKey });
    await User.save({ keyValue: 'license', value: license });
    await User.save({ keyValue: 'jwt', value: jwt });
    const { val: reportGenerationOptions } = await User.getReportGenerationOptions();
    await User.save({ keyValue: 'report-generation-options', value: reportGenerationOptions });

    const { val: docTemplateDefault } = await User.retrieveValueFromDb({
      keyValue: 'doctemplate.default',
      defaultValue: `app:orthoTemplate_odt.odt`
    });

    await User.save({ keyValue: 'doctemplate.default', value: docTemplateDefault });
    if (user) {
      Object.keys(user)
        .filter(prop => !!prop && user[prop])
        .forEach(async prop => {
          await User.save({ keyValue: prop, value: user[prop] });
        });
    }
    if (license) {
      const decryptedLicense = await ReportGeneratorUtil.decryptLicence(license);
      MyStore.set(appVersionSpecific.electronStoreLicenseDate, decryptedLicense.expiresUnixTimestamp);
    }
  }

  static async logout() {
    // const userValues = await User.findAllValues();
    //
    // Object.keys(userValues)
    //   .filter(prop => userValues[prop])
    //   .forEach(async prop => {
    //     await User.save({ keyValue: prop, value: null });
    //   });

    MyStore.delete(appVersionSpecific.electronStoreUserKey);
    MyStore.delete(appVersionSpecific.electronStoreAesKey);
    MyStore.delete(appVersionSpecific.electronStoreLicenseDate);
    MyStore.set(appVersionSpecific.electronStoreLastLoginDate, '');
  }
}
