import moment from 'moment';

import MathUtil from '../../../common/Math';
import {getLocation} from './exalangCommonImport';
import executeImport from "./predimemPredifex/ExecuteImport";
import {jsonDownload} from '../../import/utils/HappyneuronFileDownloader';

const parseFile = async ({filePath, userId, ticket, programId}) => {
  const predimem = await jsonDownload({userId, ticket, programId});

  const classe = getPredilemClass(predimem.user);

  return predimem.workouts.map(workout => ({
    firstname: predimem.user.firstName,
    lastname: predimem.user.lastName,
    birthDate: predimem.user.birthDate,
    passationDate: workout.startDate,
    passationNumber: workout.id,
    data: { workout, scores: predimem.scores },
    hnWorkoutId: workout.id,
    classe,
    text: `${predimem.user.firstName} ${predimem.user.lastName} (${workout.startDate})`
  }));
};

const getPredilemClass = user => {
  const birthDate = moment(user.birthDate, 'DD/MM/YYYY');
  const age = MathUtil.calculateAge(birthDate.toDate());
  if (!age) return '';
  let part1 = '';
  if (age >= 18 && age <= 49) {
    part1 = '18 à 49 ans';
  } else if (age >= 50 && age < 60) {
    part1 = '50 à 59 ans';
  } else if (age >= 60 && age < 70) {
    part1 = '60 à 69 ans';
  } else if (age >= 70 && age < 80) {
    part1 = '70 à 79 ans';
  } else if (age >= 80 && age < 130) {
    part1 = '80 à 130 ans';
  }
  if (!part1) return '';
  if (user.class === 14) {
    return `${part1}, > Bac`;
  }
  if (user.class === 15) {
    return `${part1}, > Bac+3`;
  }
  if (user.class === 17) {
    return `${part1}, BEP / CAP`;
  }
  return '';
};


const PredimemPredifexImport = ({ allLabeledTests, testByTestCode }) => ({
  getLocation,
  parseFile,
  executeImport: executeImport({ allLabeledTests, testByTestCode })
});
export default PredimemPredifexImport;
