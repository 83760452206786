import React, {useState} from 'react';
import {Chip, Fab, Grid, Typography} from '@material-ui/core';
import {HighlightOff, SignalWifiOff} from '@material-ui/icons';
import {useHistory} from 'react-router';
import {makeStyles} from '@material-ui/styles';

import labels from '../../constants/labels/OnLineLogin.json';
import EmailFieldFormControl from '../EmailFieldFormControl';
import Logo from '../Logo';
import UserLogin from '../common/data/UserLogin';
import server from '../../constants/server';
import log from '../Logger';
import appVersionSpecific from '../../appVersionSpecific.ortho';
import routes from '../../constants/routes';
import PasswordField from './PasswordField';
import {fetchJson} from '../common/OrthoscribeWebSite';
import MyStore from "../../actions/MyStore";
import useWindowQuery from '../common/useWindowQuery';
import {isHappyNeuronImport} from '../../actions/LicenceUtil';

function isFirstLogin() {
  const lastLoginDate = MyStore.get(appVersionSpecific.electronStoreLastLoginDate);
  return !!lastLoginDate || lastLoginDate !== '';
}

function getNextPage(firstLogin, query) {
  if (firstLogin) {
    return { pathname: routes.USER.PROFILE, state: { selectedTab: 0 } };
  }

  const ticket = query.get("ticket");
  const userId = query.get("userId");

  const { isHappyNeuronImport: isHnImport } = isHappyNeuronImport({ ticket, userId })
  if (isHnImport) {
    return { pathname: routes.IMPORT.HAPPYNEURON, state: { ticket, userId } };
  }

  return routes.HOME;
}

const useStyles = makeStyles(theme => ({
  content: {
    background: theme.palette.background.default,
    height: '100%'
  },
  textsDiv: {
    marginTop: '1em',
    marginBottom: '2em',
    '& > h5': {
      marginTop: '1em',
      marginBottom: '1em'
    }
  }
}));

const OnLineLogin = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useWindowQuery();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isCurrentlySubmitting, setIsCurrentlySubmitting] = useState(false);
  const [mailIsValid, setMailIsValid] = useState(false);
  const firstLogin = isFirstLogin();

  const login = async event => {
    event.preventDefault();

    setIsCurrentlySubmitting(true);

    const newErrors = {};

    const webSiteHost = MyStore.get(appVersionSpecific.electronStoreWebSiteHost) || server.main.url;

    try {
      const { payload, status } = await fetchJson(`${webSiteHost}/api/user/authApp`, {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: {
          'Content-Type': 'application/json',
          dataType: 'json'
        }
      });

      if (status === 'ko') {
        if (['unknown-user', 'invalid-password'].includes(payload)) {
          newErrors.badCredentials = true;
        } else if (payload === 'licence-expired') {
          newErrors.expiredLicence = true;
        } else if (payload === 'webVersion-Auth-Disabled') {
          newErrors.unauthorizedVersion = true;
        }
        await setErrors(newErrors);
        await setIsCurrentlySubmitting(false);
        return;
      }

      // update du user
      await UserLogin.login(payload, email);
    } catch (e) {
      log.error('############# ERROR #############', e);
      newErrors.networkError = true;
      await setErrors(newErrors);
      await setIsCurrentlySubmitting(false);
      return;
    }

    const nextPage = getNextPage(firstLogin, query);
    history.push(nextPage);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const errorIcons = {
    networkError: <SignalWifiOff />,
    badCredentials: <HighlightOff />,
    expiredLicence: <HighlightOff />,
    unauthorizedVersion: <HighlightOff />
  };

  const emailChanged = async newEmail => {
    setEmail(newEmail);
  };

  const firstTimeLoginLabelKey = firstLogin ? 'firstTimeLogin' : 'helloAgain';
  const firstTimeLoginOrHelloAgain = appVersionSpecific.isHappyNeuron ? 'happyneuron' : firstTimeLoginLabelKey;
  if (appVersionSpecific.isHappyNeuron) {
    const currentHref = window.location.href;
    console.log(currentHref);
    // debugger
    // prints the current URL
    window.location.href = currentHref.replaceAll("happyscribe.app", "web.orthoscribe.fr");
    return "On a changé l'url, vous ne devriez jamais tomber ici. en dev, changer le HOST du .env";
  }
  return (
    <form onSubmit={login} className={classes.content}>
      <Grid container justifyContent="center" className={classes.logo}>
        <Grid item xs={10} container direction="row">
          <Logo />
        </Grid>
        <Grid item xs={10} className={classes.textsDiv}>
          <Typography variant="h5">{labels[firstTimeLoginOrHelloAgain].header.title}</Typography>
          <Typography component="p">{labels[firstTimeLoginOrHelloAgain].header.row1}</Typography>
          <Typography component="p">{labels[firstTimeLoginOrHelloAgain].header.row2}</Typography>
        </Grid>
        <Grid item xs={10} container spacing={2} justifyContent="space-between">
          {Object.entries(errors).map(([errorKey]) => (
            <Grid item xs={12} key={errorKey}>
              <Chip icon={errorIcons[errorKey]} label={labels.errors[errorKey]} color="secondary" />
            </Grid>
          ))}
          <EmailFieldFormControl
            id="email"
            controlName="email"
            itemMdValue={6}
            placeholder={labels.form.email}
            label={labels.form.email}
            fullWidth
            value={email}
            onChangeHandler={async ({ target: { value } }) => {
              setErrors({});
              await emailChanged(value);
            }}
            mailIsValid={setMailIsValid}
            inputProps={{ required: true }}
            variant="filled"
          />
          <PasswordField
            onChangeHandler={({ target: { value } }) => {
              setPassword(value);
              setErrors({});
            }}
            password={password}
            itemMdValue={6}
            placeholder={labels.form.password}
            label={labels.form.password}
            variant="filled"
          />
          <Grid item xs={12} container justifyContent="flex-end">
            <a
              href={`${appVersionSpecific.backend.url}/login`}
              target="_blank"
              rel="noreferrer"
            >
              {labels.form.forgottenPassword}
            </a>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-start">
            <Fab
              type="submit"
              disabled={!(mailIsValid && email && password) || isCurrentlySubmitting}
              variant="extended"
              color="primary"
            >
              {labels.form.continue}
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default OnLineLogin;
