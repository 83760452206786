import React, {useEffect, useState} from 'react';
import {Grid, List, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import labels from '../../../constants/labels/patient/import/ImportPassationList.json';
import PassationListItem from './PassationListItem';
import {happyneuronTestByProgramId} from '../../../constants/tests/happyneuronTests';
import getFilePath from "./utils/HappyneuronFileDownloader";

const useStyles = makeStyles({
  fullWidth: {
    width: '100%'
  }
});

function retrieveImporterValues({ programId, ...passation }) {
  return { programId, ...passation, ...happyneuronTestByProgramId[programId] };
}

function ensureFileParserExist({ importer }) {
  return importer && importer.parseFile;
}

async function readPassationFile({ importer, fullFilePath, programId, userId, ticket, ...bilan }) {
  const passations = await importer.parseFile({filePath: fullFilePath, userId, ticket, programId})
  console.log({ passations });
  return passations.flatMap(passation => ({ ...bilan, ...passation, programId, ...importer }));
}

function addFullFilePath(userId, ticket) {
  return ({ url, programId, ...bilan }) => ({ fullFilePath: getFilePath(userId, programId, url), programId, url, userId, ticket, ...bilan });
}

function fullFilePathExist({ fullFilePath }) {
  return !!fullFilePath;
}

type Props = {
  fileContent: {
    bilans: Array,
    patient: {
      firstName?: string,
      lastName?: string
    }
  },
  ticket: string,
  userId: string,
  // eslint-disable-next-line flowtype/no-weak-types
  selectedPassations: any,
  // eslint-disable-next-line flowtype/no-weak-types
  setSelectedPassations: Function,
  noAssessmentToImport: () => void
};

const ImportPassationList = ({
  fileContent,
  selectedPassations,
  setSelectedPassations,
  ticket,
  userId,
  noAssessmentToImport
}: Props) => {
  const classes = useStyles();
  const [existingBilans, setExistingBilans] = useState([]);
  const [passations, setPassations] = useState({});

  useEffect(() => {
    const parseBilanFiles = async () => {
      if (!fileContent.assessments || fileContent.assessments.length === 0) {
        noAssessmentToImport();
        return;
      }
      const withFullPath = fileContent.assessments.filter(({ url }) => !!url).map(addFullFilePath(userId, ticket));
      const pathExist = withFullPath.filter(fullFilePathExist);
      const withImporterValue = pathExist.map(retrieveImporterValues);
      const fileParserExist = withImporterValue.filter(ensureFileParserExist);
      const map = (await Promise.all(fileParserExist.flatMap(readPassationFile))).flatMap(a=>a);
      console.log({ map });
      setExistingBilans(map);
      setPassations(selectedPassations);
    };

    parseBilanFiles().then();
  }, [fileContent, ticket, userId, noAssessmentToImport, selectedPassations]);

  const selectPassation = ({ programId, passationNumber, choosenCalcul }) => () => {
    const newSelectedPassations = { ...passations };
    if (
      selectedPassations[`${programId}-${passationNumber}`] &&
      choosenCalcul &&
      selectedPassations[`${programId}-${passationNumber}`].choosenCalcul === choosenCalcul
    ) {
      delete newSelectedPassations[`${programId}-${passationNumber}`];
      setPassations(newSelectedPassations);
      setSelectedPassations(newSelectedPassations);
    } else {
      const passation = existingBilans
        .filter(({ programId: pId, passationNumber: pn }) => programId === pId && passationNumber === pn)
        .shift();
      newSelectedPassations[`${programId}-${passationNumber}`] = {
        ...passation,
        choosenCalcul
      };
      setPassations(newSelectedPassations);
      if (Object.values(newSelectedPassations).every(({ choosenCalcul: cc }) => !!cc)) {
        setSelectedPassations(newSelectedPassations);
      } else {
        setSelectedPassations({});
      }
    }
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography>{`${labels.patientImport} ${fileContent.patient.firstName} ${fileContent.patient.lastName}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">{labels.passationSelectTitle}</Typography>
      </Grid>
      <Grid container item xs={6}>
        <List dense className={classes.fullWidth}>
          {existingBilans.map(
            ({ classe, test, programId, passationNumber, name, firstname, lastname, passationDate }) => (
              <PassationListItem
                key={`${programId}-${passationNumber}`}
                selectPassation={selectPassation}
                {...{ classe, test, programId, passationNumber, name, firstname, lastname, passationDate }}
              />
            )
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default ImportPassationList;
